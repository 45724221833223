import React, { useEffect, useState } from "react";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { useNavigate } from "react-router-dom";
import Auth from "../../../helpers/Auth";
import images from "../../../Assets/Images";
import VerifyAccount from "../../component/VerifyAccount";

export default function Challenges({leadStatus}) {
  const navigate = useNavigate();
  const { http, user, errorHandle } = Auth();
  const [chalenge, setChallenge] = useState();
  const [state, setState] = useState({
    progres: 0,
    is_lead_complete: leadStatus,
  });
  useEffect(() => {
    console.log(user);
    http
      .get(`/challenges/${user.id}`)
      .then((res) => {
        // console.log(res.data.data);
        setChallenge(res.data.data[0]);
        let total_day = 0;
        let complete = 0;
        res.data.data[0].daily_challenges.forEach((el) => {
          if (el.user_complete_challenges.length > 0) {
            complete += 1;
          }
          total_day += 1;
        });
        setState({ ...state, progres: (complete * 100) / total_day });
      })
      .catch((err) => {
        // console.log(err);
        errorHandle(err);
      });

    http
      .get(`/user-leadstatus/${user.id}`)
      .then((res) => {
        console.log(res.data);
        setState({ ...state, is_lead_complete: res.data.is_lead_complete });
      })
      .catch((err) => {
        // console.log(err)
      });
  }, []);
  const joinChalenge = () => {
    http
      .post(`/user-join-challenge`, {
        user_id: user.id,
        challenge_id: chalenge.id,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    navigate(`/challenges/${chalenge.id}`);
  };

  return (
    <>
      <div className="main_container">
        <Header />
        <div className="chapter_box">
          <div className="challenges_box">
            <h3>{chalenge && chalenge.name}</h3>
            <ul>
              <li>
                <p className="tag tag_beginner">{chalenge && chalenge.level}</p>
              </li>
              <li>
                <svg height="10" width="10" className="mx-2">
                  <circle
                    cx="4"
                    cy="4"
                    r="2"
                    strokeWidth="3"
                    fill="#6B7280"
                  ></circle>
                </svg>
              </li>
              <li>{chalenge && chalenge.duration}</li>
            </ul>
            <div className="progress-bar">
              <div
                className="line"
                style={{ width: Math.round(state.progres) + "%" }}
              ></div>
            </div>
            <div className="image_box">
              <img src={chalenge && chalenge.image} alt="" />
            </div>
            <div className="reward_box">
              <p>Reward for challenge:</p>
              <ul>
                <li>
                  <img src={images["coin.svg"]} alt="coin" />
                </li>
                <li>
                  $<span>{chalenge && chalenge.coins_reward}</span>
                </li>
              </ul>
            </div>
            <div className="btn_group">
              {chalenge && chalenge.user_join_challenge ? (
                <button
                  className="btn_theme_2"
                  onClick={() => navigate(`/challenges/${chalenge.id}`)}
                  style={{ width: "100%" }}
                >
                  VIEW
                </button>
              ) : (
                <button
                  className="btn_theme_1"
                  onClick={joinChalenge}
                  style={{ width: "100%" }}
                >
                  JOIN NOW
                </button>
              )}
            </div>
          </div>
          <div className="challenges_box" style={{ marginTop: "30px" }}>
            {/* <div className="reward_box btn_group"> */}
            <div className="image_box" style={{ position: "relative" }}>
              <img
                style={{ maxWidth: "100%" }}
                src={images["call_staff.jpg"]}
                alt=""
              />
              {/* <div
                style={{
                  position: "absolute",
                  // left: "50%",
                  bottom: "0",
                  // transform: "translate(-50%, -8%)",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <>
                  {state.is_lead_complete == 0 && (
                    <a
                      href="https://clickrdir.com/o/r97vy?lp=341"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        fontSize: "14px",
                        width: "100%",
                        padding: "10px 20px",
                        color: "#fff",
                        backgroundColor: "#795af6",
                        border: "none",
                        borderRadius: "5px",
                      }}
                    >
                      Verify Your Account Now With Our Startup Specialists
                    </a>
                  )}
                  {state.is_lead_complete == 1 && (
                    <p
                      style={{
                        fontSize: "14px",
                        width: "100%",
                        padding: "10px 20px",
                        color: "#fff",
                        backgroundColor: "#028719",
                        border: "none",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      Your Account Is Verified
                    </p>
                  )}
                </>
              </div> */}
            </div>

            {/* <p>COMING SOON</p> */}
            {/* </div> */}
          </div>
        </div>
      </div>
      <Footer />
      {!leadStatus && <VerifyAccount />}
    </>
  );
}
