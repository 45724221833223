import { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
  matchPath,
} from "react-router-dom";
import Home from "../templates/pages/Home";
import PageNotFound404 from "../templates/component/PageNotFound404";
import Auth from "../helpers/Auth";
import Course from "../templates/pages/course/Course";
import Challenges from "../templates/pages/challenges/Challenges";
import Books from "../templates/pages/books/Books";
import Profile from "../templates/pages/profile/Profile";
import SubscriptionPlan from "../templates/pages/profile/SubscriptionPlan";
import Settings from "../templates/pages/profile/Settings";
import Language from "../templates/pages/profile/Language";
import LessonContent from "../templates/pages/course/LessonContent";
import Simulator from "../templates/pages/simulator/Simulator";
import BooksInfo from "../templates/pages/books/BooksInfo";
import BooksChapter from "../templates/pages/books/BooksChapter";
import BuyAssets from "../templates/pages/simulator/BuyAssets";
import StockView from "../templates/pages/simulator/StockView";
import FinishLesson from "../templates/component/FinishLesson";
import Leaderboard from "../templates/pages/profile/Leaderboard";
import JoinChallenge from "../templates/pages/challenges/JoinChallenge";
import ChallengesTasks from "../templates/pages/challenges/ChallengesTasks";
// import TestInertQuizAnser from "../templates/pages/TestInertQuizAnser";
import ChatPopUp from "../templates/pages/ChatPopUp";
import Start from "../templates/pages/onboarding/Start";
import SelectLevel from "../templates/pages/onboarding/SelectLevel";
import Learning from "../templates/pages/onboarding/Learning";
import InverstingPath from "../templates/pages/onboarding/InverstingPath";
import Verification from "../templates/pages/Verification";

export default function AuthUser() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout, http } = Auth();
  const [leadStatus, setLeadStatus] = useState(1);

  useEffect(() => {
    // console.log(user)
    // http
    // .get(`/user-leadstatus/${user.id}`)
    // .then((res) => {
    //   // console.log(res.data);
    //   setLeadStatus(res.data.is_lead_complete);
    // })
    // .catch((err) => {
    //   // console.log(err)
    // });
    if (user) {
      if (user.isplanActive === undefined || user.isplanActive === false) {
        const patterns = [
          "/",
          "/chat",
          "/courses",
          "/courses/:course_id",
          "/challenges",
          "/challenges/:challenge_id",
          "/challenges/:challenge_id/tasks/:task_id",
          "/challenges/:challenge_id/tasks/:task_id/complete",
          "/books",
          "/books/:book_id",
          "/books/:book_id/chapter",
          "/courses/:course_id/:chapter_id/:lesson_id",
          "/courses/:course_id/:chapter_id/:lesson_id/complete",
          "/leaderboard",
          "/simulator",
          "/simulator/buy-assets",
          "/simulator/buy-assets/:asset",
          "/onboarding/start",
          "/onboarding/start/select-level",
          "/onboarding/start/learning",
          "/learning",
          "/learning/:onboarding_path_id"

          // "/profile",
          // "/profile/subscription-plan",
          // "/profile/settings",
          // "/profile/language",
          // "/verify-email",
          // "/email-login",
        ];

        const match = patterns.reduce(
          (match, pattern) =>
            match ? match : matchPath(pattern, location.pathname),
          null
        );

        if (match !== null) {
          navigate("/profile/subscription-plan");
        }
      }
    } else {
      logout();
    }

    //when user login and access guest route then redirect to navigate('/')
    const guest_routes = [
      "/auth/signin",
      "/auth/register",
      "/auth/forgot",
      "/email-login",
      "/reset-password",
    ];
    if (guest_routes.includes(location.pathname)) {
      navigate("/");
    }
  }, [location.pathname]);

  const VerifyEmail = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let email = searchParams.get("em");
    let token = searchParams.get("t");
    const { http } = Auth();

    if (email && token) {
      http
        .post("/user/verification", {
          email: email,
          verifyToken: token,
        })
        .then((res) => {
          if (res.data.status === 1) {
            navigate("/auth/signin");
          }
          // console.log(res.data)
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      navigate("/auth/signin");
    }
  };
  const EmailLogin = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let email = searchParams.get("em");
    const { http, setToken, errorHandle } = Auth();
    // setParamToken(email);
    // console.log(email)
    if (email) {
      http
        .post("/user/login/email", {
          email: email,
        })
        .then((res) => {
          // console.log(res.data)
          // if (res.data.data && res.data.data.isplanActive === true) {
          //   navigate("/");
          // }
          setToken(
            {
              id: res.data.data.id,
              name: res.data.data.name,
              email: res.data.data.email,
              isplanActive: res.data.data.isplanActive,
              accesChatGPT: res.data.data.accesChatGPT,
            },
            res.data.data.accessToken
          );
       
        })
        .catch((err) => {
          errorHandle(err);
        });
    } else {
      navigate("/auth/signin");
    }
  };
  return (
    <>
      <Routes>
        {/* <Route path="/insert-quiz-answer" element={<TestInertQuizAnser />} /> */}
        {user && user.isplanActive === true ? (
          <>
            <Route index path="/" element={<Home leadStatus={leadStatus} />} />

            {/* <Route index path="/" element={<Home coin={state} />} /> */}

            <Route path="/chat" element={<ChatPopUp />} />
            <Route path="/courses" element={<Course leadStatus={leadStatus} />} />
            <Route path="/courses/:course_id" element={<Home leadStatus={leadStatus} />} />
            <Route path="/challenges" element={<Challenges  leadStatus={leadStatus} />} />
            <Route
              path="/challenges/:challenge_id"
              element={<JoinChallenge leadStatus={leadStatus} />}
            />
            <Route
              path="/challenges/:challenge_id/tasks/:task_id"
              element={<ChallengesTasks leadStatus={leadStatus} />}
            />
            <Route
              path="/challenges/:challenge_id/tasks/:task_id/complete"
              element={<FinishLesson  />}
            />
            <Route path="/books" element={<Books leadStatus={leadStatus} />} />

            <Route path="/books/:book_id" element={<BooksInfo leadStatus={leadStatus} />}></Route>
            <Route
              path="/books/:book_id/chapter"
              element={<BooksChapter leadStatus={leadStatus} />}
            ></Route>
            <Route
              path="/courses/:course_id/:chapter_id/:lesson_id"
              element={<LessonContent leadStatus={leadStatus} />}
            />
            <Route
              path="/courses/:course_id/:chapter_id/:lesson_id/complete"
              element={<FinishLesson />}
            />
            {/* <Route path="/courses/:course_id/:chapter_id/:lesson_id/survey" element={<Feedback />} />  */}
            <Route path="/leaderboard" element={<Leaderboard leadStatus={leadStatus} />}></Route>
            <Route path="/simulator" element={<Simulator />} />
            <Route path="/simulator/buy-assets" element={<BuyAssets />} />
            <Route
              path="/simulator/buy-assets/:asset"
              element={<StockView />}
            />

            <Route path="/profile" element={<Profile />} />
            <Route
              path="/profile/subscription-plan"
              element={<SubscriptionPlan />}
            />
            <Route path="/profile/settings" element={<Settings />} />
            <Route path="/profile/language" element={<Language />} />

            <Route path="/onboarding/start" element={<Start />} />
            <Route path="/onboarding/start/select-level" element={<SelectLevel />} />
            <Route path="/onboarding/start/learning" element={<Learning />} />
            <Route path="/learning" element={<Learning />} />
            <Route path="/learning/:onboarding_path_id" element={<InverstingPath />} />


            <Route path="/verification" element={<Verification setLeadStatus={setLeadStatus}/>} />

            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/email-login" element={<EmailLogin />} />
            <Route path="/*" element={<PageNotFound404 />} />
          </>
        ) : (
          <>
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/profile/subscription-plan"
              element={<SubscriptionPlan />}
            />
            
            <Route path="/onboarding/start" element={<Start />} />
            <Route path="/onboarding/start/select-level" element={<SelectLevel />} />
            <Route path="/onboarding/start/learning" element={<Learning />} />
            <Route path="/verification" element={<Verification/>} />

            <Route path="/profile/settings" element={<Settings />} />
            <Route path="/profile/language" element={<Language />} />

            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/email-login" element={<EmailLogin />} />

            <Route path="/*" element={<PageNotFound404 />} />
          </>
        )}
      </Routes>
    </>
  );
}
